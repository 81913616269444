/*Save for safty*/
/*
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root{
  --primary-color: #0DFF92;
  --dark-color:#222222;
  --light-color: #f0f0f0;
}

body, html{
  height: 100%;
  background: var(--dark-color)
}

* > *{
    font-family: 'Poppins', sans-serif;
}

.container{
  display: block;
  position: relative;
  margin: 40px auto;
  height: auto;
  width: 800px;
  padding: 20px;
  font-size: 2rem;
}

.container .title{
    font-size: 5rem;
    text-align: center;
    border: 5px solid var(--primary-color);
    padding: .3rem .2rem;
    border-radius: 4px;
}

.text-light {
	color: var(--light-color)
}

.container ul{
  list-style: none;
  margin: 0;
  padding: 0;
	overflow:auto;
}

.container .questions{
    padding: 3rem;
}


.container .questions .qid{
  padding: 10rem 1rem;
  color: #222222;
  background-color: #0DFF92;
  border-radius: 50px;
}

.container .grid{
    margin-top: 3rem;
    font-size: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}


.container .btn:hover{
    cursor: pointer;
    background-color: #f0f0f0;
    color: #202020;
}

.next{
    background-color: var(--primary-color);
    justify-self: flex-end;
}

.prev{
    background-color: #faff5a;
    justify-self: flex-start;
}

ul li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
	border-bottom: 1px solid #333;
}

ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

ul li label{
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 2.5rem;
  padding: 25px 25px 25px 80px;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition:all 0.25s linear;
}

ul li:hover label{
	color: #FFFFFF;
}

ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 30px;
  left: 20px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

ul li:hover .checked {
  border: 5px solid #FFFFFF;
}

ul li .check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
	left: 5px;
  margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}


input[type=radio]:checked ~ .check {
  border: 5px solid var(--primary-color)
}

input[type=radio]:checked ~ .check::before{
  background: var(--primary-color)
}

input[type=radio]:checked ~ .text-primary{
  color: var(--primary-color)
} 

/* To get selected option we are using this checked class 


.checked {
  border: 5px solid var(--primary-color) !important;
}

.checked::before{
  background: var(--primary-color)
}
  */

  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

  :root {
    --primary-color: #0DFF92;
    --dark-color: #222222;
    --light-color: #f0f0f0;
  }
  
  body, html {
    height: 100%;

  }
  

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 54rem;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    font-size: 2rem;
  }

  .alert{
    margin: 10rem;
    color: #222;
    background-color: red;
    border-radius: 5px;
  }
  
  .container .title {
    font-size: 4rem;
    text-align: center;
    border: 5px solid rgb(11, 76, 142);   
     padding: .3rem .2rem;
    border-radius: 4px;
    margin-bottom: 3rem;
  }
  
  .text-light {
    color: var(--light-color);
  }
  
  .container ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  

  
  .container .questions .qid {
    padding: 10rem 1rem;
    color: #222222;
    background-color: #0DFF92;
    border-radius: 50px;
  }
  
  .container .grid {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .container .btn:hover {
    cursor: pointer;
    background-color: #0a881b;
    color: #202020;
  }
  
  .next {
    background-color: var(--primary-color);
    justify-self: flex-end;
      padding: 0.5rem 1.7rem;
      border: none;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      color:#fff;
      text-decoration: none;
      }

  
  .prev {
    background-color: #faff5a;
    justify-self: flex-start;

    
  }
  
  ul li {
    color: #AAAAAA;
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
  }
  
  ul li input[type=radio] {
    position: absolute;
    visibility: hidden;
  }
  
  ul li label {
    display: block;
    position: relative;
    font-weight: 600;
    font-size: 3rem;
    padding: 1rem 5rem;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }
  
  ul li:hover label {
    color: #FFFFFF;
  }
  
  ul li .check {
    display: block;
    position: absolute;
    border: 5px solid #AAAAAA;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    top: 1rem;
    left: 0rem;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
  }
  
  ul li:hover .checked {
    border: 5px solid #FFFFFF;
  }
  
  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    top: 0.27rem;
    left: 0.25rem;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  
  input[type=radio]:checked ~ .check {
    border: 5px solid var(--primary-color);
  }
  
  input[type=radio]:checked ~ .check::before {
    background: var(--primary-color);
  }
  
  input[type=radio]:checked ~ .text-primary {
    color: var(--primary-color);
  }
  
  .checked {
    border: 5px solid var(--primary-color) !important;
  }
  
  .checked::before {
    background: var(--primary-color);
  }
  
  /* Media Queries for Responsive Design */

  /**********************************1200px display***********************************/
  @media (min-width: 1200px) {

    ul li .check{
      height: 2.5rem;
          width: 2.5rem;
          top: 3rem;
          left: 1rem;}

ul li .check::before{
  height: 1.5rem;
  width: 1.5rem;
  top: 0.2rem;
  left: 0.2rem;}

  }
  
  @media (max-width: 1200px) {
    .container {
      width: 90%;
    }
      .container .questions .qid {
        padding: 8rem 1rem;
      }
      
      .container .questions{
        padding: 0rem 1rem 1rem 1rem;
	font-size: 1.5rem;
	      
      }
      
      .container .grid {
        font-size: 2rem;
      }
      
      ul li {
        height: 90px;
        padding: 0rem
      }
      
      ul li label {
        font-size: 3rem;
        padding: 1.7rem 0rem 1rem 3.5rem;
        
      }
      
      ul li .check {
        height: 2.8rem;
        width: 2.5rem;
        top: 2rem;
        left: 0rem;
      }
    }
      /**********************************480px display***********************************/

    @media (max-width: 480px) {
      .container {
        font-size: 1.8rem;
        max-width: 25rem;
      }
    
      .ques{
        font-size: 1.4rem;
      }
    
      .container .title {
        font-size: 2.5rem;
      }
    
    
      ul li .check {
        
        width: 2.16rem;
        left: 1rem;
        top:2.5rem;
      }
    
    
    ul li .check::before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 1.20rem;
      width: 1.3rem;
      top: 0.12rem;
      left: 0.15rem
    }
    
    
    ul li label {
        font-size: 2rem;
        padding: 2rem 1rem 0rem 2.5rem;}
    
      }
    
  
  /******************************1024 Displays**************************************/

@media (min-width: 1024px) {
  ul li .check::before {
    height: 1.8rem;
    width: 1.5rem;
    top: 3px;
    left: 0.19rem;
  }
}
  /****************************************768px display******************************/
  @media (max-width: 768px) {
  .container {
    width: 95%;
  }

  .container .title {
    font-size: 2rem;
  overflow: hidden;  }

  .container .questions .qid {
    padding: 6rem 1rem;
  }

  .container .grid {
    font-size: 1rem;
  }

  ul li {
    height: fit-content;
  }

  ul li .check {
    height: 2.5rem;
    width: 2.5rem;
    top: 19px;
    left: 1px;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 1.65rem;
  width: 1.65rem;
  top: 0.1rem;
  left: 0.125rem;
  }
}



  /******************************2k Displays**************************************/

@media (min-width: 1440px) {
  ul li .check {
    display: block;
    position: absolute;
    border: 5px solid #AAAAAA;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    top: 2rem;
    left: 1rem;

}
ul li label{
  font-size: 2.5rem;
  padding: 1rem 5rem ;
}

ul li .check::before{
  height: 2rem;
  width: 2rem;
  left: 0.19rem;
  top: 0.19rem;
}

}




  /******************************4k Displays**************************************/
@media (min-width: 2560px) {
  .container .title   {
    Max-width: 95%;
    font-size: 8rem;
    padding: 1rem;
  }
.container{
  height: 100vh;
  max-width: 100rem;
  margin-bottom: 3rem;
}
  .container .btn.prev {
      Max-width: 95%;
      font-size: 2rem;
      border-radius: 2.8;}

      .questions h2{
        font-size: 4rem;
        margin-bottom: 5rem;
      }

  ul li label{
    font-size: 6rem;
    padding :3rem 1rem 0rem 9.5rem;
    }

    ul li .check{
      height: 6rem;
      width: 5rem;
      top: 5.5rem;
      left: 1rem;
  }

  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 4.8rem;
    width: 4rem;
    top: 0.3rem;
    left: 0.19rem;
}
ul li {
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #333;

}
.next{
  padding: 1.5rem 2.7rem;
  border: none;
  border-radius: 1.5rem;
  font-size: 5.5rem;
  font-weight: 600;
}



}
