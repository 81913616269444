 *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 1px;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 8%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}


.warpper{
    color: #fff;

    min-height: 100vh;
    width: 100%;
    background-position:top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)) ,url('../../assets/english-books-resting-table-working-space.jpg');
}

.testimonial{
    padding: 10rem 10rem;
}

article h1{
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
}



article img{
    height: 200px;
    width: 200px;
    border: 2px solid #fff;
    border-radius: 50%;
    margin-top: 60px;
}

blockquote{
    font-family: sans-serif;
    font-size: 20px;
    margin-top: 30px;
}

article h5{
    text-transform: uppercase;
    font-size: 28px;
    margin-top: 30px;
}

article p{
    font-size: 17px;
} 

@media (min-width: 2560px){
    .navbar a{
        font-size:3rem;
        margin-left:10rem;
        }
        .navbar.logo{
        font-size: 4rem;
        }
    
        article h1 {
            font-size: 6rem;
            margin-top: 6rem;}
        
        
        article img {
            height: 20rem;
            width: 20rem;}
        
        blockquote {
            font-size: 2rem;
            margin-top: 2rem;}
        
        article h5 {
            font-size: 3rem;
            margin-top: 2rem;}
        
        article p {
            font-size: 1.5rem;
        }
        

}

@media (max-width: 1024px){


        .testimonial{
            padding:6rem 3rem;
        }}

@media (max-width: 768px){
    .warpper{
        min-height: 100vh;
        background-position:center;}


        .testimonial{
            padding:8rem 3rem;
        }
        article h1 {
            font-size: 2rem;}
        
        article img {
            height: 150px;
            width: 150px;}
        
        blockquote {
            font-size: 1.5rem;
            margin-top: 20px;
        }
        
        article h5 {
            font-size: 1.35rem;}
        
        article p {
            font-size: 1rem;}
    
}


@media (max-width: 480px){
    .testimonial {
        padding: 9rem 1rem;
    }
    
    article h1 {
        font-size: 1.2rem;
        }
    
    article img {
        height: 100px;
        width: 100px;
        margin-top: 20px;}
    
    blockquote {
        font-size: 1rem;
        margin-top: 20px;
    }
}