.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.3rem 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}
.header span{
    color: rgb(11, 76, 142);
}
.header::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0,0,0.1);
    backdrop-filter: blur(50px);
    z-index: -1;
}
    


 
.logo{
    font-size: 2rem;
    color: #fff;
    text-decoration: none;
    font-weight: 700;

}

.navbar a{
font-size: 1.5rem;
color: #fff;
text-decoration: none;
font-weight: 500;
margin-left: 2.5rem;

}
.navbar a:hover{
color: rgb(11, 76, 142);
transition: .5s;
}

.check {
    z-index: 1;
    display: none;
}

.icons{
    position: absolute;
    right: 5%;
    font-size: 2.8rem;
    color: #fff;
    cursor: pointer;
    display: none;
}

/*BREAKPOINTS*/
@media (max-width: 992px){
    .header{
        padding: 1.3rem 5%;
    }
}

@media (max-width: 768px){
    .icons{
        
        display: inline-flex;
    }

    .check:checked~.icons #menuIcon {
        display: none;
    }

    .icons #closeIcon {
        display:none;
    }

    .check:checked~.icons #closeIcon{
        display: block;
    }

    .navbar{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0rem;
        overflow: hidden;
        background-color: rgba(0, 0,0,0.1);
        backdrop-filter: blur(50px);
        box-shadow: 0 .5rem 1rem rgba(0, 0,0,0.1);
        transition: 0.3s ease;
    }
    .check:checked~.navbar{
        height: 10rem;
    }


    .navbar a{
        display: block;
        font-size: 1.1rem;
        margin: 1.5rem 0;
        text-align: center;
        transform: translateY(-50px);
        transition: 0.3s ease;
    }
    .check:checked~.navbar a{
        transform: translateY(0);
    }
}

@media (max-width: 450px){

        .logo{
        font-size: 1.5rem;
        }
    }
@media (min-width: 2560px){
    .navbar a{
        font-size:3rem;
        margin-left:10rem;
        }
        .logo{
        font-size: 4rem;
        }
    }

/*

.navbar a:hover{
color: #162938

}

.navBtn{
    padding: 5px;
    cursor: pointer;
    background:transparent ;
    border: none;
    outline: none;
    color: #f2f2f2;
    visibility: hidden;
    opacity: 0;
}

@media only screen and (max-width: 1024px) {
    .navBtn{

        visibility: visible;
        opacity: 1;
    }
    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:1;
        background-color: #162938;
        transition: 1sec;
        transform: translate(-100vh);
    }

    header .responsiveNav{
        transform: none;
    }
} */